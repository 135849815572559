import Hero from './components/hero';
import Instagram from './components/instagram';
import Layout from './components/layout';

import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import Lightbox from 'react-18-image-lightbox';

import { homeData } from '../graphql';
import { Image } from '../types';

import 'react-18-image-lightbox/style.css';
import '../css/lightbox.css';

const Home = () => {
  const data = homeData();
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Layout>
      {isOpen && (
        <Lightbox
          animationDisabled={true}
          enableZoom={false}
          mainSrc={data.images[photoIndex].src}
          nextSrc={data.images[(photoIndex + 1) % data.images.length].src}
          prevSrc={data.images[(photoIndex + data.images.length - 1) % data.images.length].src}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + data.images.length - 1) % data.images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % data.images.length)}
        />
      )}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-1">
        {data.thumbnails.map((image: Image, index) => {
          return (
            <div
              key={image.key}
              onClick={() => {
                setPhotoIndex(index);
                setIsOpen(true);
              }}
            >
              <GatsbyImage
                className="cursor-pointer"
                key={image.key}
                backgroundColor="#F4F4F4"
                image={{ ...image.gatsbyImage, layout: 'fullWidth' }}
                alt=""
              />
            </div>
          );
        })}
      </div>
      <Hero />
      <Instagram />
    </Layout>
  );
};

export default Home;
